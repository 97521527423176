import axios from '../../plugins/axios';
export const dashboard_product_stats = {
    state:()=>({
        pending:false,
        data:{
            current_page: 1,
            data:[],
            last_page: null,
            filter:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations:{
        fetch_stats_request(state){

            state.pending = true;

        },
        fetch_stats_success(state, payload){
            
            state.data.data = payload.product_stats.data;
            state.data.current_page = payload.product_stats.current_page;
            state.data.last_page = payload.product_stats.last_page;

            state.data.filter = payload.filter;

            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_stats_failure(state, payload){

            state.data.data = [];
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data.current_page = 1;
            state.data.data = [];
            state.data.last_page = null;

        }
    },
    actions:{
        async fetchStatsRequest({commit},{filter,page}){

            commit('fetch_stats_request');
            try{

                let res = await axios.get('/main/product/stats',{
                    params:{
                        page: page,
                        filter: filter,
                    }
                });
                if(res.data.status_code == '10093'){

                    res.data.filter = filter;
                    commit('fetch_stats_success', res.data);

                }
            }catch(ex){

                commit('fetch_stats_failure', {error: ex, message: ex});
                
            }
            
        },
    },
    getters:{
        stats(state){

            return state.data.data;

        },
        isPending(state){

            return state.pending;

        },
        filter(state){

            return state.data.filter;

        },
        currentPage(state){

            return state.data.current_page;

        },
        lastPage(state){
            
            return state.data.last_page;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}