import axios from '../../plugins/axios';
export const productCategories_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            searchedKeyword:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_categories_request(state){

            state.pending = true;

        },
        fetch_categories_success(state, payload){

            if(payload.loadData == 'new'){

                state.data.data = payload.categories.data;

            }

            if(payload.loadData == 'more'){

                payload.categories.data.forEach(element => {

                    state.data.data.push(element);

                });

            }

            state.data.current_page = payload.categories.current_page;
            state.data.last_page = payload.categories.last_page;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_categories_failure(state, payload){

            state.data.data = [];
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;
            
        },
        add_categories_list(state,payload){

            state.data.data.unshift(payload);

        },
        update_categories_list(state,payload){

            state.data.data[payload.index] = payload.category;

            if(payload.category.isActive == 0){

                state.data.data.forEach((category)=>{ 

                    if(category.parent_id && (category.parent_id == payload.category.id)){

                        category.parent_id = null;

                        category.parents = [];

                    }

                });

            }

        },
        update_categories_status(state,payload){

            state.data.data[payload.index].isActive = payload.updatedCategory.isActive;

            if(payload.updatedCategory.isActive == 0){

                state.data.data.forEach((category)=>{ 

                    if(category.parent_id && (category.parent_id == payload.updatedCategory.id)){

                        category.parent_id = null;

                        category.parents = [];

                    }

                });

            }

        },
        delete_categories_list(state,payload){

            let tempCategory = state.data.data[payload]

            state.data.data.forEach((category)=>{ 

                if(category.parent_id && (category.parent_id == tempCategory.id)){

                    category.parent_id = null;

                    category.parents = [];

                }

            });

            state.data.data.splice(payload,1);

        },
        categories_bulk_action(state,payload){
            
            if(payload.action == 'delete'){

                state.data.data = state.data.data.filter((category)=>{

                    if(!payload.listIds.includes(category.id)){

                        return category;

                    }else{

                        if(category.created_by_user == null){

                            return category;

                        }

                    }
                    
                });

            }else if(payload.action == 'statusChange'){

                state.data.data.forEach((category) =>{

                    if(payload.listIds.includes(category.id)){

                        category.isActive = payload.status;

                    }
                    if(payload.listIds.includes(category.parent_id) && payload.status == 0){

                        category.parent_id = null;

                        category.parents = [];

                    }

                });

            }
            
        },
        update_searched_keyword(state,payload){

            state.data.searchedKeyword = payload.keyword;

        },
        remove_searched_keyword(state){

            state.data.searchedKeyword = '';

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data.current_page = 1;
            state.data.data = [];
            state.data.last_page = null;
            state.data.searchedKeyword= '';

        }
    },
    actions:{
        async fetchProductCategoriesRequest({commit},{size, page, loadData}){

            commit('fetch_categories_request');
            try{
                let res = null
                if(this.state.productCategories_module.data.searchedKeyword.length > 0){
    
                    res = await axios.get('/getCategories',{
                        params:{
                            search: this.state.productCategories_module.data.searchedKeyword,
                            offset: size,
                            page: page
                        }
                    })
                }else{
                    res = await axios.get('/getCategories',{
                        params:{
                            offset: size,
                            page: page
                        }
                    })
                }
    
                if(res.data.status_code == '1003'){

                    res.data.loadData = loadData;

                    commit('fetch_categories_success', res.data,);

                }
            }catch(ex){

                commit('fetch_categories_failure', {error: ex, message: ex});

            }
        }
    },
    getters: {
        categories(state){

            return state.data.data;

        },
        keyword(state){

            return state.data.searchedKeyword;

        },
        isPending(state){

            return state.pending;

        },
        currentPage(state){

            return state.data.current_page;

        },
        lastPage(state){

            return state.data.last_page;

        },
        message(state){
            
            return state.message;

        },
        error:(state)=>state.error
    }
}