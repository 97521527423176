import store from '..';
import axios from '../../plugins/axios';

export const faqs_module = {
    state:()=>({
        pending: false,
        faqs:[],
        message: null,
        success: null,
        error: null
    }),
    mutations:{
        fetch_faqs_request(state){

            state.pending = true;

        },
        fetch_faqs_success(state, payload){
            
            state.faqs = payload
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_faqs_failure(state, payload){

            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        update_faqs(state,payload){

            state.faqs = payload;

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.faqs = [];

        },
    },
    actions:{
        async fetchFAQsRequest({commit}){

            commit("fetch_faqs_request");
            try{
                let res = await axios.get('getFAQs');
                if(res.data.status_code == "1180"){

                    commit('fetch_faqs_success',JSON.parse(res.data.faqs.content));

                }
            }catch(ex){

                commit('fetch_navigation_failure', {error: ex, message: ex});
                
            }
            
        }
    },
    getters:{
        faqs(state){

            return state.faqs;

        },
        isPending(state){

            return state.pending;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}