import axios from '../../plugins/axios';
export const countries_module = {
    state: ()=>({
        pending: false,
        data: {
            data: [],
            allCountries:[],
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_countries_request(state){

            state.pending = true;

        },
        fetch_countries_success(state, payload){

            state.data.data = payload;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_countries_failure(state, payload){

            state.data.data = [];
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        fetch_allCountries_success(state,payload){

            state.data.allCountries = payload;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_allCountries_failure(state, payload){

            state.allCountries = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data.data = [];
            state.data.allCountries = [];

        }
    },
    actions:{
        async fetchStoreCountriesRequest({commit}){

            commit('fetch_countries_request');
            try{
                let res = await axios.get('/getCountries');
                if(res.data.status_code == '1010'){

                    commit('fetch_countries_success', res.data.countries);

                }
            }catch(ex){

                commit('fetch_countries_failure', {error: ex, message: ex});

            }
        },
        async fetchAllCountries({commit}){

            commit('fetch_countries_request');
            try{
                let res = await axios.get("/countries/all");
                if(res.data.status_code == "1010"){

                    commit('fetch_allCountries_success', res.data.countries);

                }
            }catch(ex){

                commit('fetch_allCountries_failure', {error: ex, message: ex});
                
            }
        }
    },
    getters: {
        countries(state){
            
            return state.data.data;

        },
        allCountries(state){

            return state.data.allCountries;

        },
        isPending(state){

            return state.pending;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}