import axios from '@/plugins/axios';

export const contacts_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            searchedKeyword:'',
            allContacts:[],
            contactActivityTypes:[],
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_contacts_request(state){

            state.pending = true;

        },
        fetch_contacts_success(state, payload){

            if(payload.loadData == 'new'){

                state.data.data = payload.contacts.data.map(contact=>{

                    contact.isActive = contact.isActive ? true : false
                    return contact;

                })

            }
            if(payload.loadData == 'more'){

                let moreContacts = payload.contacts.data.map(contact=>{

                    contact.isActive = contact.isActive ? true : false
                    return contact;

                })
    
                moreContacts.forEach(element => {

                    state.data.data.push(element);

                });

            }

            state.data.current_page = payload.contacts.current_page
            state.data.last_page = payload.contacts.last_page
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_all_contacts_success(state, payload){

            state.data.allContacts = payload.data.contacts

        },
        fetch_contacts_failure(state, payload){

            state.data.data = [];
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message

        },
        add_contacts_list(state,payload){

            state.data.data.unshift(payload);
            
            state.data.allContacts.unshift(payload);

        },
        update_contacts_list(state,payload){

            state.data.data[payload.index] = payload.updatedContact

            if(state.data.allContacts.length > 0){

                state.data.allContacts[payload.index] = payload.updatedContact

            }

        },
        update_contacts_status(state,payload){

            state.data.data[payload.index].isActive = payload.updatedContact.isActive

        },
        delete_contacts_list(state,payload){

            state.data.data.splice(payload,1);

            if(state.data.allContacts.length > 0){

                state.data.allContacts.splice(payload,1);

            }

        },
        contacts_bulk_action(state,payload){

            state.data.data = state.data.data.filter((contact)=>{

                if(!payload.listIds.includes(contact.id)){

                    return contact

                }

            });

            if(state.data.allContacts.length > 0){

                state.data.allContacts = state.data.allContacts.filter((contact)=>{

                    if(!payload.listIds.includes(contact.id)){
    
                        return contact
    
                    }
    
                });

            }

        },
        add_contact_activity(state,payload){

            let index = state.data.data.findIndex(contact => contact.id == payload.contactId);

            if(index != -1){
                
                if(state.data.data[index].activities){

                    state.data.data[index].activities.unshift(payload.activity)

                }else{

                    let tempActivities = [];

                    tempActivities.unshift(payload.activity);

                    state.data.data[index].activities = tempActivities

                }

            }

        },
        update_contact_activity(state,payload){

            let contactIndex = state.data.data.findIndex(contact => contact.id == payload.contactId);

            if(contactIndex != -1){

                let activityIndex = state.data.data[contactIndex].activities.findIndex(activity => activity.id == payload.activity.id);

                if(activityIndex != -1){

                    state.data.data[contactIndex].activities[activityIndex] = payload.activity

                }

            }

        },
        remove_contact_activity(state,payload){

            let contactIndex = state.data.data.findIndex(contact => contact.id == payload.contactId);

            if(contactIndex != -1){
                
                let activityIndex = state.data.data[contactIndex].activities.findIndex(activity => activity.id == payload.activity.id);

                if(activityIndex != -1){

                    state.data.data[contactIndex].activities.splice(activityIndex,1)

                }

            }

        },
        update_searched_keyword(state,payload){ 

            state.data.searchedKeyword = payload.keyword

        },
        remove_searched_keyword(state){

            state.data.searchedKeyword = ''

        },
        fetch_activity_types_success(state,payload){

            let tempActivities = [];

            payload.data.activity_types.forEach((activityType) => {

                tempActivities.push(activityType.type)

            });

            state.data.contactActivityTypes = tempActivities

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data.current_page = 1;
            state.data.data = [];
            state.data.last_page = null;
            state.data.searchedKeyword= '';
            state.data.allContacts = []
            state.data.contactActivityTypes = [];

        }
    },
    actions:{
        async fetchStoreContactsRequest({commit},{size, page, loadData}){
            
            commit('fetch_contacts_request');

            try{
                
                let res = null

                if(this.state.contacts_module.data.searchedKeyword.length > 0){

                    res = await axios.get('/crm/contact/all',{
                        params:{
                            search: this.state.contacts_module.data.searchedKeyword,
                            offset: size,
                            page: page
                        }
                    })

                }else{
                    res = await axios.get('/crm/contact/all',{
                        params:{
                            offset: size,
                            page: page,
                        }
                    })
                }

                if(res.data.status_code == '1460'){

                    res.data.loadData = loadData

                    commit('fetch_contacts_success', res.data)

                }

            }catch(ex){

                commit('fetch_contacts_failure', {error: ex, message: ex})

            }
            
        },
        async fetchAllContactsRequest({commit}){

            try{

                let res = await axios.get('crm/contact/all');
                if(res.data.status_code == '1460'){

                    commit('fetch_all_contacts_success', res)

                }
            }catch(ex){

            }

        },
        async fetchContactTypesRequest({commit}){

            let res = await axios.get('crm/contact/log/type/all');
            if(res.data.status_code == '1469'){

                commit('fetch_activity_types_success', res)

            }

        },
    },
    getters: {
        contacts(state){

            return state.data.data;

        },
        allContacts(state){

            return state.data.allContacts;
            
        },
        activityTypes(state){

            return state.data.contactActivityTypes;

        },
        keyword(state){

            return state.data.searchedKeyword;

        },
        isPending(state){

            return state.pending;
            
        },
        currentPage(state){

            return state.data.current_page;

        },
        lastPage(state){

            return state.data.last_page;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}