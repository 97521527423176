import axios from '../../plugins/axios';
export const bar_graph_module = {
    state: ()=>({
        pending: false,
        data: {
            years:[],
            data: [],
            selectedYear:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_bar_graph_request(state){

            state.pending = true;

        },
        fetch_bar_graph_success(state, payload){

            state.data.data = payload
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_bar_graph_failure(state, payload){
            
            state.data.years = [];
            state.data.data = [];
            state.data.selectedYear = '';

            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        year_change(state,payload){

            state.data.selectedYear = payload;

        },
        add_expenses_list(state,payload){

            state.data.data.unshift(payload);

        },
        update_expenses_list(state,payload){

            const index = state.data.data.findIndex(x=>x.id === payload.id);
  
            if(index !== -1){

                state.data.data[index] = payload;

            }

        },
        delete_expenses_list(state,payload){

            state.data.data.splice(payload,1);

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data.years = [];
            state.data.data = [];
            state.data.selectedYear = '';

        }
    },
    actions:{
        async fetchBarGraphRequest({commit},payload){

            commit('fetch_bar_graph_request');
            try{
                this.state.bar_graph_module.data.selectedYear = payload
                let res = await axios.get(`/getSalesVsExpenses?year=${payload}`);
                if(res.data.status_code == '1220'){

                    commit('fetch_bar_graph_success', res.data);

                }
            }catch(ex){

                commit('fetch_bar_graph_failure', {error: ex, message: ex});
                
            }
        },
    },
    getters: {
        barGraphData(state){

            return state.data.data;

        },
        storeYear(state){

            return state.data.selectedYear;

        },
        isPending(state){

            return state.pending;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}