import axios from '../../plugins/axios';
export const communication_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            searchedKeyword:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_communications_request(state){

            state.pending = true;

        },
        fetch_communications_success(state, payload){

            if( payload.loadData == 'new'){

                state.data.data = payload.support.data;

            }

            if(payload.loadData == 'more'){

                let moreCustomers = payload.support.data;

                moreCustomers.forEach(element => {

                    state.data.data.push(element);

                });

            }

            state.data.current_page = payload.support.current_page;
            state.data.last_page = payload.support.last_page;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_communication_failure(state, payload){

            state.data.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        add_communication_list(state,payload){

            state.data.data.unshift(payload);

        },
        update_communication_list(state,payload){

            state.data.data[payload.index] = payload.updatedMember;

        },
        update_communication_status(state,payload){

            state.data.data[payload.index].isActive = payload.updatedCustomer.isActive;

        },
        delete_communication_list(state,payload){

            state.data.data.splice(payload,1);

        },
        update_searched_keyword(state,payload){ 

            state.data.searchedKeyword = payload.keyword;

        },
        remove_searched_keyword(state){

            state.data.searchedKeyword = '';

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data.current_page = 1;
            state.data.data = [];
            state.data.last_page = null;
            state.data.searchedKeyword = '';

        }
    },
    actions:{
        async fetchStoreCommunicationsRequest({commit},{size, page, loadData}){

            commit('fetch_communications_request');
            try{
                let res = null

                if(this.state.communication_module.data.searchedKeyword.length > 0){

                    res = await axios.get('/chat-support/all',{
                        params:{
                            search: this.state.communication_module.data.searchedKeyword,
                            offset: size,
                            page: page
                        }
                    })

                }else{

                    res = await axios.get('/chat-support/all',{
                        params:{
                            offset: size,
                            page: page
                        }
                    })

                }
                if(res.data.status_code == '1270'){

                    res.data.loadData = loadData;

                    commit('fetch_communications_success', res.data);

                }
            }catch(ex){
                
                commit('fetch_communication_failure', {error: ex, message: ex});
                
            }

        }

    },
    getters: {
        communications(state){

            return state.data.data;

        },
        keyword(state){

            return state.data.searchedKeyword;

        },
        isPending(state){

            return state.pending;

        },
        currentPage(state){

            return state.data.current_page;

        },
        lastPage(state){

            return state.data.last_page;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}