<template>
  <div v-if="!isDisable" class="ai__modal">
    <button class="open__ai-btn" type="button" @click.prevent="handleOpenModal">
      <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.62425 10.9208C5.15316 10.7358 5.22156 10.0487 5.71987 9.96029L10.4348 9.12332C10.6066 9.09283 10.7502 8.97525 10.8139 8.81286L12.564 4.35547C12.749 3.88438 13.436 3.95278 13.5245 4.45109L14.3615 9.16604C14.392 9.33781 14.5095 9.48139 14.6719 9.54515L19.1293 11.2953C19.6004 11.4802 19.532 12.1673 19.0337 12.2557L14.3188 13.0927C14.147 13.1232 14.0034 13.2408 13.9396 13.4031L12.1895 17.8605C12.0046 18.3316 11.3175 18.2632 11.2291 17.7649L10.3921 13.05C10.3616 12.8782 10.244 12.7346 10.0816 12.6709L5.62425 10.9208Z" fill="white"/>
        <path d="M2.11958 19.8466C1.6485 19.6616 1.7169 18.9746 2.2152 18.8861L4.50092 18.4804C4.67269 18.4499 4.81627 18.3323 4.88003 18.1699L5.72845 16.009C5.91341 15.538 6.60046 15.6064 6.68892 16.1047L7.09466 18.3904C7.12515 18.5622 7.24273 18.7057 7.40512 18.7695L9.56598 19.6179C10.0371 19.8029 9.96866 20.4899 9.47036 20.5784L7.18464 20.9841C7.01287 21.0146 6.86929 21.1322 6.80553 21.2946L5.95711 23.4554C5.77215 23.9265 5.0851 23.8581 4.99665 23.3598L4.5909 21.0741C4.56041 20.9023 4.44283 20.7588 4.28044 20.695L2.11958 19.8466Z" fill="#1A7C88"/>
        <path opacity="0.4" d="M3.85272 4.2706C3.38163 4.08564 3.45003 3.39859 3.94833 3.31013L5.01433 3.1209C5.1861 3.09041 5.32968 2.97283 5.39344 2.81044L5.78912 1.80268C5.97408 1.33159 6.66113 1.39999 6.74959 1.89829L6.93882 2.96429C6.96931 3.13606 7.08689 3.27964 7.24928 3.3434L8.25704 3.73908C8.72813 3.92404 8.65973 4.61109 8.16143 4.69955L7.09543 4.88878C6.92366 4.91927 6.78008 5.03685 6.71632 5.19924L6.32064 6.207C6.13568 6.67809 5.44863 6.60969 5.36017 6.11139L5.17094 5.04539C5.14045 4.87362 5.02287 4.73004 4.86048 4.66628L3.85272 4.2706Z" fill="white"/>
      </svg>
    </button>
    <div class="modalBg passwordResetPopup" :class="isOpen ? 'modal-active': ''">
      <div class="modalLayers" @click.prevent="isOpen = false"></div>
      <div class="modalBody">
        <div class="ai__modal-wrapper">
          <div class="ai__stars-layer">
            <img src="../../assets/stars.png" alt="">
          </div>
          <div class="ai__modal-title">
            <h2>Start with AI</h2>
            <p>Please speak in any language</p>
          </div>
          <div class="ai__generate-field">
            <textarea :disabled="isRecording" v-model="text"></textarea>
            <VoiceRecorder ref="childRef" :on-update="updateDuration" :on-success="onSuccessRecording" :on-start="onStartRecording" />
          </div>
          <div class="record__time" v-show="getDuration && isRecording">
            <h3>{{ getDuration }}</h3>
          </div>
          <div class="generate__btn">
            <button type="button" :disabled="isRecording" @click.prevent="generateContent">
              <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.45869 9.42759C3.9876 9.24262 4.056 8.55557 4.5543 8.46712L7.80973 7.88923C7.9815 7.85874 8.12508 7.74116 8.18884 7.57878L9.3972 4.50118C9.58216 4.03009 10.2692 4.09849 10.3577 4.5968L10.9355 7.85222C10.966 8.02399 11.0836 8.16757 11.246 8.23133L14.3236 9.43969C14.7947 9.62465 14.7263 10.3117 14.228 10.4002L10.9726 10.978C10.8008 11.0085 10.6572 11.1261 10.5935 11.2885L9.38509 14.3661C9.20013 14.8372 8.51308 14.7688 8.42462 14.2705L7.84675 11.0151C7.81625 10.8433 7.69867 10.6997 7.53629 10.6359L4.45869 9.42759Z" fill="white"/>
                <path d="M1.71262 16.4211C1.24154 16.2362 1.30994 15.5491 1.80824 15.4607L3.16033 15.2207C3.3321 15.1902 3.47568 15.0726 3.53944 14.9102L4.04131 13.632C4.22627 13.1609 4.91332 13.2293 5.00178 13.7276L5.24179 15.0797C5.27228 15.2514 5.38986 15.395 5.55225 15.4588L6.83048 15.9607C7.30156 16.1456 7.23316 16.8327 6.73486 16.9211L5.38277 17.1611C5.211 17.1916 5.06742 17.3092 5.00366 17.4716L4.50179 18.7498C4.31683 19.2209 3.62978 19.1525 3.54132 18.6542L3.30131 17.3021C3.27082 17.1303 3.15324 16.9868 2.99085 16.923L1.71262 16.4211Z" fill="#1A7C88"/>
                <path opacity="0.4" d="M3.07064 4.21713C2.59956 4.03217 2.66795 3.34511 3.16626 3.25666L3.56268 3.18629C3.73445 3.1558 3.87803 3.03822 3.94179 2.87583L4.08893 2.50106C4.27389 2.02998 4.96095 2.09838 5.0494 2.59668L5.11977 2.9931C5.15026 3.16488 5.26784 3.30845 5.43023 3.37221L5.805 3.51936C6.27608 3.70432 6.20768 4.39137 5.70938 4.47983L5.31296 4.5502C5.14118 4.58069 4.99761 4.69827 4.93385 4.86065L4.7867 5.23542C4.60174 5.7065 3.91469 5.63811 3.82623 5.1398L3.75587 4.74338C3.72537 4.57161 3.60779 4.42803 3.44541 4.36427L3.07064 4.21713Z" fill="white"/>
              </svg>
              Generate Content
            </button>
          </div>
        </div>
        <div class="modalCloseIcon">
          <button type="button" @click.prevent="isOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
              <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <h6>{{ disableMessage }}</h6>
  </div>
</template>
<script>
import VoiceRecorder from './voiceRecorder.vue'

export default {
  data: ()=>({
    isOpen: false,
    text: '',
    isRecording: false,
    isDisable: false,
    disableMessage: '',
    getDuration: null
  }),
  props: {
    prefix: {
      type: String,
      required: true,
    },
    textLength: {
      type: Number,
      default: 250
    },
    onSuccess:{
      type: Function,
      required: true
    }
  },
  components: {
    VoiceRecorder: VoiceRecorder
  },
  methods: {
    handleOpenModal(){

      this.isOpen = true;
      this.text = '';

    },
    onSuccessRecording(txt){

      this.text = txt
      this.isRecording = false
      this.getDuration = null;

    },
    onStartRecording(){

      this.isRecording = true;

    },
    async generateContent(){

      if(!this.text || this.text == ''){

        this.$message({
          type: 'error',
          showClose: true,
          message:  'To proceed with content generation, enter some text.',
        })
        
        return ;

      }else if(this.text.length < '50'){

        this.$message({
          type: 'error',
          showClose: true,
          message:  'To ensure high-quality content, please input a minimum of 50 characters.',
        });

        return ;

      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        // background: 'rgba(0, 0, 0, 0.7)'
      });

      try{
        let res = await this.$axios.post('/ai/generate', {
          text: this.prefix + ' ' + this.text
        })
        this.isOpen = false;

        this.onSuccess(res.data.data.text);

      }catch(error){

        if(error.response){

          if(error.response.data.error.includes("doesn't support")){

            let subscriptionPath = window.location.origin + "/subscription";

            this.$message({
              type: 'error',
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: error.response.data.error 
              + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        loading.close();

      }

    },
    updateDuration(duration){

      this.getDuration = duration

    }
  },
  mounted(){

    if(!this.prefix || this.prefix == ''){

      this.isDisable = true;
      this.disableMessage = 'prefix prop is required of type string'

    }
    if(!this.onSuccess){

      this.isDisable = true;
      this.disableMessage = 'onSuccess prop is required of type function'

    }

  },
  created(){
    // console.log(this.$refs.childRef)
    // this.$refs.child.$on('duration-updated', duration => {
    //   this.getDuration = duration;
    // });
  }
}
</script>
<style>
/* .ai__modal-wrapper{ */
  /* position: relative; */
/* } */
.ai__modal .modalBg .modalBody{
  width: 287.5px;
  border-radius: 5.99px;
  border: 1.2px solid #E9E9E9;
  padding: 35.78px 11.38px 29.5px 12.39px;
  position: relative;
}
.ai__modal-title{
  text-align: center;
  margin-bottom: 12.29px;
}
.ai__modal-title h2{
  font-size: 19.17px;
  line-height: 28.75px;
  letter-spacing: 0.16px;
  color: #000000;
  font-weight: 800;
  margin-bottom: 3.01px;
}
.ai__modal-title p{
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: #787878;
  font-weight: 400;
  font-style: italic;
  margin-bottom: 0px;
}
.ai__generate-field{
  position: relative;
  margin-bottom: 32px;
}
.ai__generate-field textarea{
  min-width: 100%;
  max-width: 100%;
  border: 1.2px solid #D6D6D6;
  background-color: #FCFCFC;
  border-radius: 0px;
  outline: none;
  padding: 11.46px 10px;
  min-height: 185.85px;
  max-height: 185.85px;
  font-size: 9.68px;
  line-height: 14.37px;
  letter-spacing: 0.16px;
  color: #000;
}
.ai__generate-field button{
  position: absolute;
  bottom: -16.95px;
  right: 13.75px;
  border: 2.72px solid #29B5C7CC;
  width: 37.93px;
  height: 37.93px;
  border-radius: 50%;
  background: linear-gradient(174.68deg, #1C3761 42.43%, #1C9AA1 155.4%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.ai__generate-field button svg{
  fill: #fff;
  width: 18px;
  height: 18px;
}
.ai__stars-layer{
  width: 47.86px;
  height: 49.1px;
  position: absolute;
  top: 4.2px;
  left: 6.7px;
}
.ai__stars-layer img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.generate__btn{
  text-align: center;
  margin-top: 6.85px;
}
.generate__btn button{
  background: linear-gradient(174.68deg, #1C3761 42.43%, #1C9AA1 124.28%);
  display: inline-flex;
  align-items: center;
  gap: 9.66px;
  padding: 10.6px 20.61px 10.6px 15.3px;
  border: none;
  border-radius: 5.06px;
  font-size: 14.37px;
  line-height: 21.56px;
  color: #fff;
  letter-spacing: 0.16px;
}
.record__time{
  text-align: center;
  margin-top: 7.4px;
}
.record__time h3{
  font-size: 25.84px;
  line-height: 38.75px;
  color: #F05260;
  letter-spacing: 0.16px;
  font-weight: 800;
  margin-bottom: 0px;
}
/*  */
.tableFromFieldBox{
  position: relative;
}
.open__ai-btn{
  position: absolute;
  bottom: -22.95px;
  right: 6.75px;
  border: 2.72px solid #29B5C7CC;
  width: 37.93px;
  height: 37.93px;
  border-radius: 50%;
  background: linear-gradient(174.68deg, #1C3761 42.43%, #1C9AA1 155.4%);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>