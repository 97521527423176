<template>
    <router-view />
</template>

<script>
export default {
  beforeMount(){
    
    if (window.location.hostname.startsWith('www.')) {
      // Replace "www" with "app" in the hostname

      const newURL = window.location.href.replace('www.', 'app.');
      
      // Redirect to the new URL
      window.location.href = newURL;
    }

  },
  mounted() {
    // Checking if it's the production environment
    if (process.env.VUE_APP_ENV === 'production') {
      // Loading the script here
      const clarityScript = document.createElement("script");

      clarityScript.setAttribute('type','text/javascript');

      const clarityCode = `(function(c,l,a,r,i,t,y){ 
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; 
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; 
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })
      (window, document, "clarity", "script", "o4dcn2gjun"); `

      clarityScript.innerHTML = clarityCode

      document.head.appendChild(clarityScript);

    }

    // Checking if it's the production environment
    if (process.env.VUE_APP_ENV === 'production') {
      
      const googleTagScript = document.createElement('script');

      googleTagScript.src  = 'https://www.googletagmanager.com/gtag/js?id=G-5XSQRGD8V4'

      // Set the async attribute to true
      googleTagScript.async = true;

      document.head.appendChild(googleTagScript)

      const gTagScript = document.createElement('script');

      const gTagCode = `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', 'G-5XSQRGD8V4');`

      gTagScript.innerHTML = gTagCode

      document.head.appendChild(gTagScript);

    }

  },
};
</script>
