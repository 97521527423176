import axios from '../../plugins/axios';
export const content_blocks = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            searchedKeyword:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_content_blocks_request(state){

            state.pending = true;

        },
        fetch_content_blocks_success(state, payload){

            state.data.data = payload.blocks
            // state.data.current_page = payload.customers.current_page
            // state.data.last_page = payload.customers.last_page
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_moreCustomers_success(state,payload){

            let moreCustomers = payload.customers.data.map(customer=>{
                customer.isActive = customer.isActive ? true : false
                return customer;
            })

            moreCustomers.forEach(element => {

                state.data.data.push(element);

            });

            state.data.current_page = payload.customers.current_page
            state.data.last_page = payload.customers.last_page
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_content_blocks_failure(state, payload){
            
            state.data.data = payload;
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        add_customers_list(state,payload){

            state.data.data.unshift(payload);

        },
        update_customers_list(state,payload){

            state.data.data[payload.index] = payload.updatedCustomer;

        },
        update_customer_status(state,payload){

            state.data.data[payload.index].isActive = payload.updatedCustomer.isActive;

        },
        delete_customers_list(state,payload){

            state.data.data.splice(payload,1);

        },
        update_searched_keyword(state,payload){ 

            state.data.searchedKeyword = payload.keyword;

        },
        remove_searched_keyword(state){

            state.data.searchedKeyword = '';

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data.current_page = 1;
            state.data.data = [];
            state.data.last_page = null;
            state.data.searchedKeyword= '';

        }
    },
    actions:{
        async fetchContentBlocksRequest({commit}){

            commit('fetch_content_blocks_request');
            try{

                let res = null
                this.state.content_blocks.data.current_page = 1
                if(this.state.content_blocks.data.searchedKeyword.length > 0){

                    res = await axios.get(`/getContentBlocks?search=${this.state.content_blocks.data.searchedKeyword}`);

                }else{

                    res = await axios.get(`/getContentBlocks?page=${this.state.content_blocks.data.current_page}`);

                }
                if(res.data.status_code == '1175'){

                    commit('fetch_content_blocks_success', res.data);

                }
            }catch(ex){

                commit('fetch_content_blocks_failure', {error: ex, message: ex});

            }
        },
        async fetchMoreCustomersRequest({commit}){

            try{
                if(this.state.customers_module.data.current_page < this.state.customers_module.data.last_page){

                    commit('fetch_content_blocks_request');

                    let res = null

                    if(this.state.customers_module.data.searchedKeyword.length > 0){

                        res = await axios.get(`/getCustomers?search=${this.state.customers_module.data.searchedKeyword}&page=${this.state.customers_module.data.current_page + 1}`);
                    
                    }else{

                        res = await axios.get(`/getCustomers?page=${this.state.customers_module.data.current_page + 1}`);
                    }
                    if(res.data.status_code == '1017'){

                        commit('fetch_moreCustomers_success', res.data);

                    }

                }
            }catch(ex){

                commit('fetch_content_blocks_failure', {error: ex, message: ex});

            }
        },
    },
    getters: {
        contentBlocks(state){

            return state.data.data;

        },
        keyword(state){

            return state.data.searchedKeyword;

        },
        isPending(state){

            return state.pending;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}