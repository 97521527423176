import axios from '../../plugins/axios';
export const store_categories_module = {
    state: ()=>({
        pending: false,
        data: {
            data: []
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_store_categories_request(state){

            state.pending = true;

        },
        fetch_store_categories_success(state, payload){
            
            let othersIndex = payload.data.findIndex((x)=>x.name == "Others");

            let othersCategory = payload.data[othersIndex];

            payload.data.splice(othersIndex,1);

            payload.data.push(othersCategory);

            state.data.data = payload.data;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_store_categories_failure(state, payload){

            state.data.data = [];
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data.data = [];

        }
    },
    actions:{
        async fetchStoreCategoriesRequest({commit}){

            commit('fetch_store_categories_request');
            try{
                let res = await axios.get('/getStoreCategories');
                if(res.data.status_code == '1155'){

                    commit('fetch_store_categories_success', res.data.store_categories);

                }
            }catch(ex){

                commit('fetch_store_categories_failure', {error: ex, message: ex});
                
            }

        },
    },
    getters: {
        categories(state){

            return state.data.data;

        },
        isPending(state){

            return state.pending;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}