import axios from '../../plugins/axios';
export const home_banner_module = {
    state: ()=>({
        pending: false,
        data: [],
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_banner_request(state){

            state.pending = true;

        },
        fetch_banner_success(state, payload){

            state.data = payload.banners;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_banner_failure(state, payload){

            state.data.data = [];
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        add_banner(state, payload){

            state.data.push(payload);

        },
        delete_banner(state, payload){

            let banners = state.data.filter(banner => banner.id != payload.id);
            state.data = banners;

        },
        update_banner_position(state, payload){

            state.data = payload;

        },
        update_banner(state,payload){

            let index = state.data.findIndex(x=>x.id === payload.banner_id)

            state.data[index] = payload.updatedBanner;

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data = [];

        }
    },
    actions:{
        async fetchBannerRequest({commit}){

            commit('fetch_banner_request');
            try{
                let res = await axios.get('/getHomeBanner');
                if(res.data.status_code == '1140'){

                    commit('fetch_banner_success', res.data);

                }
            }catch(ex){

                commit('fetch_banner_failure', {error: ex, message: ex});
                
            }
        }
    },
    getters: {
        banners(state){

            return state.data;

        },
        isPending(state){

            return state.pending;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}