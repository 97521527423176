import axios from '../../plugins/axios';
export const blogs_module = {
    state: ()=>({
        pending: false,
        data: {
            current_page: 1,
            data: [],
            last_page: null,
            searchedKeyword:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_blogs_request(state){

            state.pending = true;

        },
        fetch_blogs_success(state, payload){

            if(payload.loadData == 'new'){

                state.data.data = payload.blogs.data;

            }
            if(payload.loadData == 'more'){

                let moreCustomers = payload.blogs.data;

                moreCustomers.forEach(element => {

                    state.data.data.push(element);

                });

            }

            state.data.current_page = payload.blogs.current_page;
            state.data.last_page = payload.blogs.last_page;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_blogs_failure(state, payload){

            state.data.data = [];
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        add_blogs_list(state,payload){

            state.data.data.unshift(payload);

        },
        update_blogs_list(state,payload){

            const index = state.data.data.findIndex(x=>x.id === payload.id)
  
            if(index !== -1){

                state.data.data[index] = payload;

            }

        },
        update_blogs_status(state,payload){

            state.data.data[payload.index].isActive = payload.updatedBlog.isActive
            
        },
        delete_blogs_list(state,payload){

            state.data.data.splice(payload,1);

        },
        blogs_bulk_action(state,payload){

            if(payload.action == 'delete'){

                state.data.data = state.data.data.filter((blog)=>{

                    if(!payload.listIds.includes(blog.id)){
                        
                        return blog

                    }

                });

            }else if(payload.action == 'statusChange'){

                state.data.data.forEach((blog) =>{

                    if(payload.listIds.includes(blog.id)){

                        blog.isActive = payload.status

                    }

                });

            }

        },
        update_searched_keyword(state,payload){ 

            state.data.searchedKeyword = payload.keyword;

        },
        remove_searched_keyword(state){

            state.data.searchedKeyword = ''

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data.current_page = 1;
            state.data.data = [];
            state.data.last_page = null;
            state.data.searchedKeyword= '';

        }
    },
    actions:{
        async fetchStoreBlogsRequest({commit},{size, page, loadData}){
            
            commit('fetch_blogs_request');
            try{

                let res = null

                if(this.state.blogs_module.data.searchedKeyword.length > 0){
                    
                    res = await axios.get('blog/all',{
                        params:{
                            search: this.state.blogs_module.data.searchedKeyword,
                            offset: size,
                            page: page
                        }
                    })

                }else{

                    res = await axios.get('blog/all',{
                        params:{
                            offset: size,
                            page: page
                        }
                    })

                }
                if(res.data.status_code == '1038'){

                    res.data.loadData = loadData;

                    commit('fetch_blogs_success', res.data);

                }
            }catch(ex){

                commit('fetch_blogs_failure', {error: ex, message: ex});
                
            }

        }
    },
    getters: {
        blogs(state){

            return state.data.data;

        },
        isPending(state){

            return state.pending;

        },
        currentPage(state){

            return state.data.current_page;

        },
        lastPage(state){

            return state.data.last_page;

        },
        keyword(state){

            return state.data.searchedKeyword;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}