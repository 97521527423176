import axios from '../../plugins/axios';
export const line_graph_module = {
    state: ()=>({
        pending: false,
        data: {
            years:[],
            data: [],
            days:7,
            filter:'',
            from:'',
            to:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_line_graph_request(state){

            state.pending = true;

        },
        fetch_line_graph_success(state, payload){

            state.data.data = payload
            state.data.days = payload.days
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_line_graph_failure(state, payload){

            state.data.years = [];
            state.data.data = [];
            state.data.days = 7;
            state.data.filter = '';
            state.data.from = '';
            state.data.to = '';

            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        add_expenses_list(state,payload){

            state.data.data.unshift(payload);

        },
        update_expenses_list(state,payload){

            const index = state.data.data.findIndex(x=>x.id === payload.id)
  
            if(index !== -1){

                state.data.data[index] = payload;

            }

        },
        delete_expenses_list(state,payload){

            state.data.data.splice(payload,1);

        },
        CLEAR_MODULE(state){

            state.pending = false
            state.message = null
            state.success = null
            state.error = null

            state.data.years = []
            state.data.data = []
            state.data.days = 7
            state.data.filter = ''
            state.data.from = ''
            state.data.to = ''

        }
        
    },
    actions:{
        async fetchLineGraphRequest({commit},payload){

            commit('fetch_line_graph_request');
            try{
                let res = null;
                this.state.line_graph_module.data.days = payload.days
                this.state.line_graph_module.data.filter = payload.filter
                
                if(payload.filter == "3months"){

                    this.state.line_graph_module.data.from = ''
                    this.state.line_graph_module.data.to = ''

                    res = await axios.get(`/getCustomersVsVisitors?filter=${payload.filter}`);

                }else if(payload.filter == "range"){

                    this.state.line_graph_module.data.filter = payload.filter
                    this.state.line_graph_module.data.from = payload.from
                    this.state.line_graph_module.data.to = payload.to
                    
                    res = await axios.get(`/getCustomersVsVisitors?filter=${payload.filter}&from=${payload.from}&to=${payload.to}`);

                }else{

                    this.state.line_graph_module.data.from = ''
                    this.state.line_graph_module.data.to = ''

                    res = await axios.get(`/getCustomersVsVisitors?days=${payload.days}`);

                }
                
                if(res.data.status_code == '1221'){

                    commit('fetch_line_graph_success', res.data);

                }
            }catch(ex){

                commit('fetch_line_graph_failure', {error: ex, message: ex});

            }
        },
    },
    getters: {
        lineGraphData(state){

            return state.data.data;

        },
        isPending(state){

            return state.pending;

        },
        selectedFilter(state){

            return state.data.filter;

        },
        days(state){

            return state.data.days;

        },
        from(state){

            return state.data.from;

        },
        to(state){

            return state.data.to;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}