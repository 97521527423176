import store from '..';
import axios from '../../plugins/axios';

export const form_summary_module = {
    state:()=>({
        pending: false,
        data:{
            current_page: 1,
            data: [],
            last_page: null,
        },
        message: null,
        success: null,
        error: null
    }),
    mutations:{
        fetch_form_summary_request(state){

            state.pending = true;

        },
        fetch_form_summary_success(state, payload){

            if(payload.loadData == 'new'){

                state.data.data = payload.forms.data

            }

            if(payload.loadData == 'more'){
            
                payload.forms.data.forEach((form) => {

                    state.data.data.push(form)

                });
                
            }

            state.data.current_page = payload.forms.current_page;
            state.data.last_page = payload.forms.last_page;

            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_form_summary_failure(state, payload){

            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        update_form_summary(state,payload){

            state.data = payload;

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data.current_page = 1;
            state.data.data = [];
            state.data.last_page = null;

        },
    },
    actions:{
        async fetchFormSummaryRequest({commit},{size, page, loadData}){

            commit("fetch_form_summary_request");
            try{
                let res = await axios.get('getFormSubmissions',{
                    params:{
                        offset: size,
                        page: page
                    }
                });
                if(res.data.status_code == "1111"){

                    res.data.loadData = loadData;

                    commit('fetch_form_summary_success',res.data);
                    
                }
            }catch(ex){

                commit('fetch_form_summary_failure', {error: ex, message: ex});
                
            }
            
        }
    },
    getters:{
        formSummaries(state){

            return state.data.data;

        },
        isPending(state){

            return state.pending;

        },
        currentPage(state){

            return state.data.current_page;

        },
        lastPage(state){

            return state.data.last_page;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}