import axios from '@/plugins/axios';

export const email_templates_module = {
    state: ()=>({
        pending: false,
        data: {
            // current_page: 1,
            data: [],
            // last_page: null,
            searchedKeyword:'',
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_email_templates_request(state){

            state.pending = true;

        },
        fetch_email_templates_success(state, payload){

            state.data.data = payload.templates
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_email_templates_failure(state, payload){

            state.data.data = [];
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message

        },
        add_email_template(state,payload){

            state.data.data.unshift(payload)

        },
        update_email_template(state,payload){

            let index = state.data.data.findIndex(template => template.id == payload.id);

            if(index != -1){

                state.data.data[index] = payload

            }

        },
        delete_email_template(state,payload){
            
            state.data.data = state.data.data.filter((template) =>{

                if(template.id != payload.id){

                    return template

                }

            });

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            // state.data.current_page = 1
            state.data.data = [];
            // state.data.last_page = null
            state.data.searchedKeyword= '';

        }
    },
    actions:{
        async fetchStoreEmailTemplatesRequest({commit}){

            commit('fetch_email_templates_request');

            try{
                
                let res = null

                if(this.state.email_templates_module.data.searchedKeyword.length > 0){

                    res = await axios.get('/crm/email/template/all',{
                        params:{
                            search: this.state.email_templates_module.data.searchedKeyword,
                        }
                    })

                }else{
                    res = await axios.get('/crm/email/template/all');
                }

                if(res.data.status_code == '1470'){

                    commit('fetch_email_templates_success', res.data);

                }

            }catch(ex){

                commit('fetch_email_templates_failure', {error: ex, message: ex});

            }
            
        },
    },
    getters:{
        templates(state){

            return state.data.data;

        },
        keyword(state){

            return state.data.searchedKeyword;

        },
        isPending(state){

            return state.pending;

        },
        currentPage(state){

            return state.data.current_page;

        },
        lastPage(state){

            return state.data.last_page;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}