<template>
    <div>
          <!-- section slider -->
        <div id="demo" class="carousel slide" data-ride="carousel">

            <!-- Indicators -->
            <ul class="carousel-indicators remove">
              <li data-target="#demo" data-slide-to="0" class="active"></li>
              <li data-target="#demo" data-slide-to="1"></li>
              <li data-target="#demo" data-slide-to="2"></li>
            </ul>
            
            <!-- The slideshow -->
            <div class="carousel-inner">

              <div class="carousel-item slider-yellow active">
                <div class="container nav-container home-slider">
                    <div class="row slider-display">
                        <div class="col-md-6">
                            <h1 data-aos="fade-down-right" data-aos-once="true">BUILD YOUR<br>ONLINE PRESENCE.</h1>
                            <div data-aos="fade-right" data-aos-duration="2000" data-aos-once="true" class="opportunity-button home-btn">
                                <button>GET STARTED</button>
                                <p>Start Your First Web Site Trial Today<br> No Credit Card Required!</p>
                            </div>
                        </div>

                        <div class="col-md-6 cycle-img">
                            <img data-aos="fade-up" data-aos-once="true" class="img-fluid" src="../assets/images/Bg1@2x.png">
                            <img data-aos="fade-right"  data-aos-duration="3000" data-aos-once="true" class="cycle" src="../assets/images/Bike@2x.png">
                        </div>
                    </div>
                </div>
                <div class="conatiner-fluid slider-arrow">
                    <div class="row">
                        <div class="col-2">
                            <a href="#door-id"><img class="vert-move" src="../assets/images/Arrows.png"></a>
                        </div>
                    </div>
                </div>
              </div>


              <!-- slider-2 -->

              <div class="carousel-item slider-red">
                <div class="container nav-container home-slider">
                    <div class="row slider-display">
                        <div class="col-md-6">
                            <h1 data-aos="fade-down-right" data-aos-once="true">BUILD YOUR<br>ONLINE PRESENCE.</h1>
                            <div data-aos="fade-right" data-aos-duration="2000" data-aos-once="true" class="opportunity-button home-btn">
                                <button>GET STARTED</button>
                                <p>Start Your First Web Site Trial Today<br> No Credit Card Required!</p>
                            </div>
                        </div>

                        <div class="col-md-6 cycle-img">
                            <img data-aos="fade-up" data-aos-once="true" class="img-fluid" src="../assets/images/JacketBG@2x.png">
                            <img data-aos="fade-right" data-aos-duration="3000" data-aos-once="true" class="shirts" src="../assets/images/Jackets@2x.png">
                            
                        </div>
                    </div>
                </div>
                <div class="conatiner-fluid slider-arrow">
                    <div class="row">
                        <div class="col-2">
                            <a href="#door-id"><img class="vert-move" src="../assets/images/Arrows.png"></a>
                        </div>
                    </div>
                </div>
              </div>


              <!-- slider-3 -->

              <div class="carousel-item slider-cyan">
                <div class="container nav-container home-slider">
                    <div class="row slider-display">
                        <div class="col-md-6">
                            <h1 data-aos="fade-down-right" data-aos-once="true">BUILD YOUR<br>ONLINE PRESENCE.</h1>
                            <div data-aos="fade-right" data-aos-duration="2000" data-aos-once="true" class="opportunity-button home-btn">
                                <button>GET STARTED</button>
                                <p>Start Your First Web Site Trial Today<br> No Credit Card Required!</p>
                            </div>
                        </div>

                        <div class="col-md-6 cycle-img">
                           <img data-aos="fade-up" data-aos-once="true" class="img-fluid" src="../assets/images/ScooterBG@2x.png">
                            <img data-aos="fade-right" data-aos-duration="3000" data-aos-once="true" class="scooter" src="../assets/images/Scooter@2x.png">
                        </div>
                    </div>
                </div>
                <div class="conatiner-fluid slider-arrow">
                    <div class="row">
                        <div class="col-2">
                            <a href="#door-id"><img class="vert-move" src="../assets/images/Arrows.png"></a>
                        </div>
                    </div>
                </div>
              </div>
              
            </div>
            
            <!--Disable Left and right controls -->

            <!-- <a class="carousel-control-prev remove" href="#demo" data-slide="prev">
              <span class="carousel-control-prev-icon"></span>
            </a>
            <a class="carousel-control-next remove" href="#demo" data-slide="next">
              <span class="carousel-control-next-icon"></span>
            </a> -->
          </div>


        <!-- section-door -->
        <div class="gap" id="door-id"></div>
        <section class="door door-remove">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-5 col-lg-6 door-img">
                        <h1 data-aos="fade-right" data-aos-once="true">Turn Your Ideas<br>Into Reality.</h1>
                    </div>
                    <div class="col-md-7 col-lg-6 door-content">
                        <ul>
                            <li data-aos="fade-up" data-aos-once="true">
                                <h2>01. Select Your Store Template</h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                                </p>
                            </li>
                            <li data-aos="fade-up" data-aos-once="true">
                                <h2>02. Customize The Template</h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                                </p>
                            </li>
                            <li data-aos="fade-up" data-aos-once="true">
                                <h2>03. Add Products You Want to Sale</h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                                </p>
                            </li>
                            <li data-aos="fade-up" data-aos-once="true">
                                <h2>04. Reach Your Customers Online</h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                                </p>
                            </li>
                            <li>
                                <a data-aos="fade-right"  data-aos-duration="3000" data-aos-once="true" class="get-start" href="#">GET STARTED 
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 41">
                                        <path id="Union_1" data-name="Union 1" class="cls-1" d="M0,35.41,16,20.5,0,5.592V0L22,20.5h0L0,41Z"/>
                                      </svg>
                                </a>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <!-- mobile view of door section -->
        <section class="door-mobile-view">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 px-0 display-door">
                        <img data-aos="fade-up" data-aos-once="true" class="small-door" src="../assets/images/small-door.png" alt="">
                        <div class="door-img">
                        <h1 data-aos="fade-right" data-aos-once="true">Turn Your Ideas<br>Into Reality.</h1>
                        <a class="get-start btn-template ml-0" href="#">GET STARTED
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 41">
                                <path id="Union_2" data-name="Union 1" class="cls-1" d="M0,35.41,16,20.5,0,5.592V0L22,20.5h0L0,41Z"/>
                              </svg>
                        </a>
                    </div>
                    </div>
                    <div class="col-12 px-0">

                        <div id="demo3" class="carousel slide" data-ride="carousel">
                        
                            <!-- Indicators -->
                            <ul class="carousel-indicators blue-carousel">
                              <li data-target="#demo3" data-slide-to="0" class="active"></li>
                              <li data-target="#demo3" data-slide-to="1"></li>
                            </ul>
                            
                            <!-- The slideshow -->
                            <div class="carousel-inner">

                              <div class="carousel-item blue-slider door-content active">
                                <ul>
                                    <li data-aos="fade-up" data-aos-once="true">
                                        <h2>01. Select Your Store Template</h2>
                                        <p>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                                        </p>
                                    </li>
                                    <li data-aos="fade-up" data-aos-once="true">
                                        <h2>02. Customize The Template</h2>
                                        <p>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                                        </p>
                                    </li>
                                </ul>    
                              </div>
                              <div class="carousel-item door-content blue-slider">
                                <ul>
                                    <li data-aos="fade-up" data-aos-once="true">
                                        <h2>03. Add Products You Want to Sale</h2>
                                        <p>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                                        </p>
                                    </li>
                                    <li data-aos="fade-up" data-aos-once="true">
                                        <h2>04. Reach Your Customers Online</h2>
                                        <p>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                                        </p>
                                    </li>
                                </ul> 
                              </div>

                            </div>
                          </div>

                    </div>
                </div>
            </div>
        </section>

        <!-- templates-section -->
        <section class="templates">
            <div class="container template-container">
                <div class="row">
                    <div class="col-md-12 template-heading">
                        <h1 data-aos="fade-right" data-aos-once="true">Templates for Every<br> Business Sector.</h1>
                    </div>
                </div>
                <div class="row bussiness-sectors">
                    <div class="col-md-6 pink-img">
                        <img data-aos="fade-up" data-aos-once="true" class="pink-img-1" src="../assets/images/BgPink@2x.png" alt="pink-box">
                        <img data-aos="fade-right" data-aos-once="true" class="pink-img-2 shirt-remove" src="../assets/images/Shirts@2x.png" alt="cloths">
                        <img data-aos="fade-right" data-aos-once="true" class="pink-img-2 new-shirt" src="../assets/images/newShirts@2x.png" >

                    </div>
                    <div class="col-md-6 bussiness-padding">
                        <div class="row bussiness-content">
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy 
                                eirmod tempor invidunt ut labore et dolore magna aliquyam erat, 
                                sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
                        </div>
                        <div class="row business-list">
                            <div class="col-6 col-md-6">
                                <ul data-aos="fade-up" data-aos-once="true">
                                    <li>
                                        <div class="bar"></div>
                                        <div class="bar-content">Fashion</div>
                                    </li>
                                    <li>
                                        <div class="bar"></div>
                                        <div class="bar-content">Grocery</div>
                                    </li>
                                    <li>
                                        <div class="bar"></div>
                                        <div class="bar-content">Entertainment</div>
                                    </li>
                                    <li>
                                        <div class="bar"></div>
                                        <div class="bar-content">Hardware</div>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-6 col-md-6">
                                <ul data-aos="fade-up" data-aos-once="true">
                                    <li>
                                        <div class="bar"></div>
                                        <div class="bar-content">Electronic</div>
                                    </li>
                                    <li>
                                        <div class="bar"></div>
                                        <div class="bar-content">Hardware</div>
                                    </li>
                                    <li>
                                        <div class="bar"></div>
                                        <div class="bar-content">Books</div>
                                    </li>
                                    <li>
                                        <div class="bar"></div>
                                        <div class="bar-content">Pets</div>
                                    </li>
                                </ul>
                            </div>
                            <a data-aos="fade-right" data-aos-once="true" class="get-start btn-template" href="#">ALL TEMPLATES
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 41">
                                    <path id="Union_2" data-name="Union 1" class="cls-1" d="M0,35.41,16,20.5,0,5.592V0L22,20.5h0L0,41Z"/>
                                  </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- =======online store======== -->

        <section class="online-store">
            <div class="container-fluid">
                <div class="row store-heading">
                    <div class="col-md-7 col-lg-7 col-xl-7">
                        <h1 data-aos="fade-right" data-aos-once="true">Everything You Need<br>in Your Online Store.</h1>
                    </div>
                </div>
                <div class="row reverce-row">
                    <div class="col-md-6 store-left-side">
                        <P><strong>MERIWEBSHOP</strong> ecommerce solution comes with the following features.</P>
                           <div class="business-list split-remove-col">
                            <ul class="">
                                <li data-aos="fade-right" data-aos-once="true">
                                    <div class="bar"></div>
                                    <div class="bar-content">Online Store Ownership</div>
                                </li>
                                <li data-aos="fade-right" data-aos-once="true">
                                    <div class="bar"></div>
                                    <div class="bar-content">Theme Customization</div>
                                </li>
                                <li data-aos="fade-right" data-aos-once="true">
                                    <div class="bar"></div>
                                    <div class="bar-content">SEO Friendly</div>
                                </li>
                                <li data-aos="fade-right" data-aos-once="true">
                                    <div class="bar"></div>
                                    <div class="bar-content">Easy Products Management</div>
                                </li>
                                <li data-aos="fade-right" data-aos-once="true">
                                    <div class="bar"></div>
                                    <div class="bar-content">Inventory Management</div>
                                </li>
                                <li data-aos="fade-right" data-aos-once="true">
                                    <div class="bar"></div>
                                    <div class="bar-content">Payment Integration</div>
                                </li>
                                <li data-aos="fade-right" data-aos-once="true">
                                    <div class="bar"></div>
                                    <div class="bar-content">Shipping Management [TCS]</div>
                                </li>
                                <li data-aos="fade-right" data-aos-once="true">
                                    <div class="bar"></div>
                                    <div class="bar-content">Reporting and Analysis Tool</div>
                                </li>
                                <li data-aos="fade-right" data-aos-once="true">
                                    <div class="bar"></div>
                                    <div class="bar-content">Minimum Vendor Commissions</div>
                                </li>
                                <li data-aos="fade-right" data-aos-once="true">
                                    <div class="bar"></div>
                                    <div class="bar-content">Free Assistance to Manage Store</div>
                                </li>
                            </ul>
                        </div>
                        <!-- ============= Responsive new list============ -->
                        <div class="row business-list split-col-show">
                            <div class="col-6 col-md-6">
                                <ul data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate">
                                    <li>
                                        <div class="bar"></div>
                                        <div class="bar-content">Online Store Ownership</div>
                                    </li>
                                    <li>
                                        <div class="bar"></div>
                                        <div class="bar-content">Theme Customization</div>
                                    </li>
                                    <li>
                                        <div class="bar"></div>
                                        <div class="bar-content">SEO Friendly</div>
                                    </li>
                                    <li>
                                        <div class="bar"></div>
                                        <div class="bar-content">Easy Products Management</div>
                                    </li>
                                    <li>
                                        <div class="bar"></div>
                                        <div class="bar-content">Inventory Management</div>
                                    </li>
                                    <li>
                                        <div class="bar"></div>
                                        <div class="bar-content">Free Assistance to Manage Store</div>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-6">
                                <ul data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate">
                                    <li>
                                        <div class="bar"></div>
                                        <div class="bar-content">Payment Integration</div>
                                    </li>
                                    <li>
                                        <div class="bar"></div>
                                        <div class="bar-content">Shipping Management [TCS]</div>
                                    </li>
                                    <li>
                                        <div class="bar"></div>
                                        <div class="bar-content">Reporting and Analysis Tool</div>
                                    </li>
                                    <li>
                                        <div class="bar"></div>
                                        <div class="bar-content">Minimum Vendor Commissions</div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        


                        <a data-aos="fade-right" data-aos-once="true" class="get-start btn-template left-m" href="#">GET STARTED
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 41">
                                <path id="Union_2" data-name="Union 1" class="cls-1" d="M0,35.41,16,20.5,0,5.592V0L22,20.5h0L0,41Z"/>
                              </svg>
                        </a>
                    </div>
                    <div class="col-md-6 store-right-side">
                        <img data-aos="fade-down" data-aos-once="true"  data-aos-duration="3000" src="../assets/images/Laptop@2x.png" alt="laptop">
                    </div>
                </div>
                <div class="row store-heading bottom-heading">
                    <div class="col-md-12 col-lg-7 col-xl-7">
                        <h1 data-aos="fade-right" data-aos-once="true">Have a Look What is Our<br> Satisfied Clients Feedback.</h1>
                    </div>
                </div>
            </div>
        </section>
        <!-- banner-clients -->  
        <section class="clients">
            <div class="conatiner-fluid">
                <div class="row">
                    <div class="col-10 col-md-7 col-lg-6 client-col">
                          <div id="demo1" class="carousel slide negative-slider client-arrow" data-ride="carousel">
                    
                        <!-- Indicators -->
                        <ul class="carousel-indicators small-indicator">
                          <li data-target="#demo1" data-slide-to="0" class="active"></li>
                          <li data-target="#demo1" data-slide-to="1"></li>
                          <li data-target="#demo1" data-slide-to="2"></li>
                          <li data-target="#demo1" data-slide-to="3"></li>
                          <li data-target="#demo1" data-slide-to="4"></li>
                        </ul>

                        <!-- The slideshow -->
                        <div class="carousel-inner small-carousel-inner">
                          <div class="carousel-item slider-cyan small-slider active">
                          <h2>FALAK KAZMI</h2>
                          <img src="../assets/images/MaskGroup68.png" alt="colon">
                          <p>As an online business owner, website design is the most important factor 
                              in choosing an eCommerce platform. It’s what sets the first impression, 
                              draws your customers in, and tells the story of your brand.”</p>
                              <a class="get-start ml-0" href="#">CREATE ONLINE STORE 
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 41">
                                    <path id="Union_1" data-name="Union 1" class="cls-1" d="M0,35.41,16,20.5,0,5.592V0L22,20.5h0L0,41Z"/>
                                  </svg>
                            </a>
                          </div>
                        
                          <div class="carousel-item small-slider slider-cyan">
                             <h2>FALAK KAZMI</h2>
                          <img src="../assets/images/MaskGroup68.png" alt="colon">
                          <p>As an online business owner, website design is the most important factor 
                              in choosing an eCommerce platform. It’s what sets the first impression, 
                              draws your customers in, and tells the story of your brand.”</p>
                              <a class="get-start ml-0" href="#">CREATE ONLINE STORE 
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 41">
                                    <path id="Union_1" data-name="Union 1" class="cls-1" d="M0,35.41,16,20.5,0,5.592V0L22,20.5h0L0,41Z"/>
                                  </svg>
                            </a>
                          </div>
                          <div class="carousel-item small-slider slider-cyan">
                             <h2>FALAK KAZMI</h2>
                          <img src="../assets/images/MaskGroup68.png" alt="colon">
                          <p>As an online business owner, website design is the most important factor 
                              in choosing an eCommerce platform. It’s what sets the first impression, 
                              draws your customers in, and tells the story of your brand.”</p>
                              <a class="get-start ml-0" href="#">CREATE ONLINE STORE 
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 41">
                                    <path id="Union_1" data-name="Union 1" class="cls-1" d="M0,35.41,16,20.5,0,5.592V0L22,20.5h0L0,41Z"/>
                                  </svg>
                            </a>
                          </div> 
                        
                         <div class="carousel-item small-slider slider-cyan">
                             <h2>FALAK KAZMI</h2>
                          <img src="../assets/images/MaskGroup68.png" alt="colon">
                          <p>As an online business owner, website design is the most important factor 
                              in choosing an eCommerce platform. It’s what sets the first impression, 
                              draws your customers in, and tells the story of your brand.”</p>
                              <a class="get-start ml-0" href="#">CREATE ONLINE STORE 
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 41">
                                    <path id="Union_1" data-name="Union 1" class="cls-1" d="M0,35.41,16,20.5,0,5.592V0L22,20.5h0L0,41Z"/>
                                  </svg>
                            </a>
                          </div>

                          <div class="carousel-item small-slider slider-cyan">
                            <h2>FALAK KAZMI</h2>
                         <img src="../assets/images/MaskGroup68.png" alt="colon">
                         <p>As an online business owner, website design is the most important factor 
                             in choosing an eCommerce platform. It’s what sets the first impression, 
                             draws your customers in, and tells the story of your brand.”</p>
                             <a class="get-start ml-0" href="#">CREATE ONLINE STORE 
                               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 41">
                                   <path id="Union_1" data-name="Union 1" class="cls-1" d="M0,35.41,16,20.5,0,5.592V0L22,20.5h0L0,41Z"/>
                                 </svg>
                           </a>
                         </div>
                        </div>

                        <!-- Left and right controls -->
                        
                        <a class="client-sign-setting" href="#demo1" data-slide="prev">
                            <img class="client-img-1" src="../assets/images/left-plan.png">
                        </a>
                        <a class="client-sign-setting-1" href="#demo1" data-slide="next">
                            <img class="client-img-2" src="../assets/images/right-plan.png">
                        </a>
                      </div>
                </div>
            </div>
        </div>

        </section>

         <!-- business-plans -->  
         <section class="business-plans resp-b-plan">
            <div class="container nav-container">
                <div class="row businessplan-row">
                    <div class="col-md-12 col-lg-10">
                            <h1 data-aos="fade-right" data-aos-once="true">Our Affordable Business<br>Plans to Cover Up Your Needs.</h1>
                    </div>
                </div>
                <div class="row plan-row plan-row-1">
                    <div data-aos="fade-right" data-aos-once="true" class="col-md-4 plan-red">
                        <div class="plan-heading resp-plan-h">
                            <h1>Business</h1>
                        </div>
                        <div class="plan-content resp-plan-h">
                            <h1><span class="rupees">Rs</span>1,500</h1>
                            <p>Per Month</p>
                        </div>
                        <div class="plan-list">
                            <ul>
                                <li>Unlimited Products</li> 
                          
                                <li>5 GB File Storage</li> 
                          
                                <li>Free Theme</li> 
                          
                                <li>Free Store Setup</li> 
                          
                                <li>Free Vendor Mobile App</li> 
                           
                                <li>Hosting with 1 GB Space</li> 
                         
                                <li>No Ads</li> 
                            </ul>
                        </div>
                        <button data-aos="fade-up" data-aos-once="true" class="plan-btn">GET STARTED</button>
                    </div>

                    <div data-aos="fade-up" data-aos-once="true" class="col-md-4 plan-yellow">
                        <div class="plan-heading  resp-plan-h">
                            <h1>Free</h1>
                        </div>
                        <div class="plan-content resp-plan-h">
                            <h1><span class="rupees">Rs</span>0.00</h1>
                            <p>One Month Free Trial</p>
                        </div>
                        <div class="plan-list yellow-list">
                            <ul>
                                <li>100 Products Quota</li> 
                           
                                <li>1 GB File Storage</li> 
                           
                                <li>Free Theme</li> 
                           
                                <li>Free Store Setup</li> 
                           
                                <li>Free Vendor Mobile App</li> 
                           
                                <li>Hosting with 1 GB Space</li> 
                           
                                <li>Minimum Ads</li> 
                            </ul>
                        </div>
                        <button data-aos="fade-up" data-aos-once="true" class="plan-btn">GET STARTED</button>
                    </div>


                    <div data-aos="fade-left" data-aos-once="true" class="col-md-4 plan-cyan">
                        <div class="plan-heading resp-plan-h">
                            <h1>Enterprise</h1>
                        </div>
                        <div class="plan-content resp-plan-h">
                            <h1><span class="rupees">Rs</span>5000</h1>
                            <p>Per Month</p>
                        </div>
                        <div class="plan-list">
                            <ul>
                                <li>Unlimited Products</li> 
                         
                                <li>5 GB File Storage</li> 
                         
                                <li>Free Theme</li> 
                         
                                <li>Free Store Setup</li> 
                          
                                <li>Free Vendor Mobile App</li> 
                           
                                <li>Hosting with 1 GB Space</li> 
                          
                                <li>No Ads</li> 
                            </ul>
                        </div>
                        <button data-aos="fade-up" data-aos-once="true" class="plan-btn">GET STARTED</button>
                    </div>
                </div>

                <!-- slider business plan -->
                    <div class="row slider-plan">
                        <div class="col-12">

                                <div id="demo5" class="carousel slide plan-rep-slider" data-ride="carousel">

                                 <!-- Left and right controls -->
                                 <a href="#demo5" data-slide="prev">
                                    <img class="plan-1" src="../assets/images/left-plan.png">
                                </a>
                                <a href="#demo5" data-slide="next">
                                    <img class="plan-2" src="../assets/images/right-plan.png">
                                </a>

                                    <!-- The slideshow -->
                                    <div class="carousel-inner">
                                      <div class="carousel-item plan-row">
                                     
                                        <div class="plan-red">
                                            <div class="plan-heading plan-h1">
                                                <h1>Business</h1>
                                            </div>
                                            <div class="plan-content plan-h2">
                                                <h1><span class="rupees">Rs</span>1,500</h1>
                                                <p>Per Month</p>
                                            </div>
                                            <div class="plan-list">
                                                <ul>
                                                    <li>Unlimited Products</li> 
                                              
                                                    <li>5 GB File Storage</li> 
                                              
                                                    <li>Free Theme</li> 
                                              
                                                    <li>Free Store Setup</li> 
                                              
                                                    <li>Free Vendor Mobile App</li> 
                                               
                                                    <li>Hosting with 1 GB Space</li> 
                                             
                                                    <li>No Ads</li> 
                                                </ul>
                                            </div>
                                            <button class="plan-btn">GET STARTED</button>
                                        </div>
                                      </div>
                                     
                <!-- =======slider-2 ========== -->

                                      <div class="carousel-item plan-row active">
                                     
                                        <div class="plan-yellow">
                                            <div class="plan-heading plan-h1">
                                                <h1>Free</h1>
                                            </div>
                                            <div class="plan-content plan-h2">
                                                <h1><span class="rupees">Rs</span>0.00</h1>
                                                <p>One Month Free Trial</p>
                                            </div>
                                            <div class="plan-list yellow-list">
                                                <ul>
                                                    <li>100 Products Quota</li> 
                                               
                                                    <li>1 GB File Storage</li> 
                                               
                                                    <li>Free Theme</li> 
                                               
                                                    <li>Free Store Setup</li> 
                                               
                                                    <li>Free Vendor Mobile App</li> 
                                               
                                                    <li>Hosting with 1 GB Space</li> 
                                               
                                                    <li>Minimum Ads</li> 
                                                </ul>
                                            </div>
                                            <button class="plan-btn">GET STARTED</button>
                                        </div>
                                      </div>


                    <!--============== slider-3================ -->

                                      <div class="carousel-item plan-row">
                                     
                                        <div class="col-md-4 plan-cyan">
                                            <div class="plan-heading plan-h1">
                                                <h1>Enterprise</h1>
                                            </div>
                                            <div class="plan-content plan-h2">
                                                <h1><span class="rupees">Rs</span>5000</h1>
                                                <p>Per Month</p>
                                            </div>
                                            <div class="plan-list">
                                                <ul>
                                                    <li>Unlimited Products</li> 
                                             
                                                    <li>5 GB File Storage</li> 
                                             
                                                    <li>Free Theme</li> 
                                             
                                                    <li>Free Store Setup</li> 
                                              
                                                    <li>Free Vendor Mobile App</li> 
                                               
                                                    <li>Hosting with 1 GB Space</li> 
                                              
                                                    <li>No Ads</li> 
                                                </ul>
                                            </div>
                                            <button class="plan-btn">GET STARTED</button>
                                        </div>

                                      </div>
                                    </div>

                                    <!-- Indicators -->
                                    <ul class="carousel-indicators plan-carousel">
                                        <li data-target="#demo5" data-slide-to="0" class="active"></li>
                                        <li data-target="#demo5" data-slide-to="1"></li>
                                        <li data-target="#demo5" data-slide-to="2"></li>
                                      </ul>


                                  </div>
                        </div>
                    </div>

            </div>
        </section>
        <section class="guide">
            <div class="container nav-container">
                <div class="row">
                    <div class="col-md-12 guide-heading">
                        <h1 data-aos="fade-right" data-aos-once="true">Guides For<br>Getting Started.</h1>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <div class="row reverce-row">
                    <div class="col-md-4 guide-img">
                        <img data-aos="fade-up" data-aos-once="true"  data-aos-duration="2500" src="../assets/images/BigLogo@2x.jpg" alt="guide-logo-img">
                    </div>
                    <div class="col-md-8 guide-content">
                        <div class="business-list">
                            <ul data-aos="fade-right" data-aos-once="true">
                                <li>
                                    <div class="count">01.</div>
                                    <div class="bar-content">Find an e-commerce template and start your free trial.</div>
                                </li>
                                <li>
                                    <div class="count">02.</div>
                                    <div class="bar-content">Register or transfer your business’s domain name.</div>
                                </li>
                                <li>
                                    <div class="count">03.</div>
                                    <div class="bar-content">Set up your online store by adding products and connecting a payment processor.</div>
                                </li>
                                <li>
                                    <div class="count">04.</div>
                                    <div class="bar-content">If you sell services, set up appointments through MERIWEBSHOP scheduling.</div>
                                </li>
                                <li>
                                    <div class="count">05.</div>
                                    <div class="bar-content">Customize online store categories and content with the website customization.</div>
                                </li>
                                <li>
                                    <div class="count">06.</div>
                                    <div class="bar-content">Grow your online store with email marketing and SEO tools.</div>
                                </li>
                                <li>
                                    <div class="count">07.</div>
                                    <div class="bar-content">Simply approach us though our email or contact number</div>
                                </li>
                                <li>
                                    <div class="count">08.</div>
                                    <div class="bar-content">You will be contacted by our sales persons within 24 hours to setup your account & website theme etc.</div>
                                </li>
                                <li>
                                    <div class="count">09.</div>
                                    <div class="bar-content">Your free shop assistant will upload your products, guide you about the process and make it up & running.</div>
                                </li>
                            </ul>
                            <a data-aos="fade-right" data-aos-once="true" class="get-start btn-template remove-guide-btn" href="#">GET STARTED
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 41">
                                    <path id="Union_2" data-name="Union 1" class="cls-1" d="M0,35.41,16,20.5,0,5.592V0L22,20.5h0L0,41Z"/>
                                  </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- news-section -->
        <section class="news">
            <div class="container nav-container">
                <div class="row news-remove">
                    <div class="col-md-5">
                        <img data-aos="fade-down" data-aos-once="true" class="img-fluid" src="../assets/images/Img3@2x.jpg" alt="galary">
                        <p data-aos="fade-right" data-aos-once="true">Green Trystan Reese an Activist<br>and Parenthood</p>
                        <img data-aos="fade-down" data-aos-once="true" class="img-fluid" src="../assets/images/Img1@2x.jpg" alt="galary">
                        <p data-aos="fade-right" data-aos-once="true">Pink History Month:<br>Creative Origins</p>
                    </div>

                    <div class="col-md-7 news-updates">
                        <div class="news-right-section">
                        <h1 data-aos="fade-right" data-aos-once="true">Stay Aware With Our News and Updates.</h1>
                        <p class="explore-p">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.</p>
                        <a data-aos="fade-right" data-aos-once="true" class="get-start btn-template explore-btn" href="#">EXPLORE OUR BLOG	
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 41">
                                <path id="Union_2" data-name="Union 1" class="cls-1" d="M0,35.41,16,20.5,0,5.592V0L22,20.5h0L0,41Z"/>
                              </svg>
                        </a>
                    </div>

                        <img data-aos="fade-down" data-aos-once="true" class="img-fluid" src="../assets/images/Img2@2x.jpg" alt="galary">
                        <p data-aos="fade-right" data-aos-once="true">Pink 4 Sites Supporting Others<br>During Coronavirus</p>
                        <img data-aos="fade-down" data-aos-once="true" class="img-fluid" src="../assets/images/Img4@2x.jpg" alt="galary">
                        <p data-aos="fade-right" data-aos-once="true">Blue Honoring Cultural Heritage<br>Through Cuisine</p>

                    </div>
                </div>

                <!-- mobile-news-section -->
                <div class="row mobile-view-news">
                    <div class="col-12">
                        <div class="news-updates">
                            <h1 data-aos="fade-right" data-aos-once="true">Stay Aware With Our News and Updates.</h1>
                            <p class="explore-p">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et.</p>
                            <a data-aos="fade-right" data-aos-once="true" class="get-start btn-template explore-btn explore-btn-resp " href="#">EXPLORE OUR BLOG	
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 41">
                                    <path id="Union_2" data-name="Union 1" class="cls-1" d="M0,35.41,16,20.5,0,5.592V0L22,20.5h0L0,41Z"/>
                                  </svg>
                            </a>
                        </div>

                        <div id="demo4" class="carousel slide resp-galary" data-ride="carousel">
                         
                            <!-- The slideshow -->
                            <div class="carousel-inner">

                              <div class="carousel-item news-carousel active">
                                <img data-aos="fade-down" data-aos-once="true" src="../assets/images/flower-1.png">
                                <p data-aos="fade-right" data-aos-once="true">Green Trystan Reese an Activist<br>and Parenthood</p>
                              </div>

                              <div class="carousel-item news-carousel">
                                <img data-aos="fade-down" data-aos-once="true" src="../assets/images/flower-2.png">
                                <p data-aos="fade-right" data-aos-once="true">Pink History Month:<br>Creative Origins</p>
                              </div>
                              
                              <div class="carousel-item news-carousel">
                                <img data-aos="fade-down" data-aos-once="true" src="../assets/images/shop-1.png">
                                 <p data-aos="fade-right" data-aos-once="true">Pink 4 Sites Supporting Others<br>During Coronavirus</p>
                              </div>

                              <div class="carousel-item news-carousel">
                                <img data-aos="fade-down" data-aos-once="true" src="../assets/images/ceramic.png">
                                 <p data-aos="fade-right" data-aos-once="true">Blue Honoring Cultural Heritage<br>Through Cuisine</p>
                              </div>

                            </div>
                               <!-- Indicators -->
                               <ul class="carousel-indicators news-carousel dots-m">
                                <li data-target="#demo4" data-slide-to="0" class="active"></li>
                                <li data-target="#demo4" data-slide-to="1"></li>
                                <li data-target="#demo4" data-slide-to="2"></li>
                                <li data-target="#demo4" data-slide-to="4"></li>
                              </ul>
                              
                          </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- section support -->

        <section class="support">
            <div class="container nav-container">
                <div class="row">
                    <div class="col-md-6 support-content support-resp-btn">
                        <h1 data-aos="fade-right">
                            24/7 Support to<br>Help you Out.
                        </h1>
                        <p data-aos="fade-left" data-aos-once="true">
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor 
                            invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.
                        </p>
                        <a data-aos="fade-right" data-aos-once="true" class="get-start left-m help-btn" href="#">HELP CENTER
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 41">
                                <path id="Union_1" data-name="Union 1" class="cls-1" d="M0,35.41,16,20.5,0,5.592V0L22,20.5h0L0,41Z"/>
                              </svg>
                        </a>
                    </div>
                    <div class="col-md-6 accordion-p">
                        <!-- accordion -->
                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                            <div data-aos="fade-right" data-aos-once="true" class="panel panel-default">
                                <div class="panel-heading" role="tab" id="headingOne">
                                    <h4 class="panel-title">
                                        <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            What does a MERIWEBSHOP subscription include?
                                            <img src="../assets/images/+sign.png">
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                                    <div class="panel-body">
                                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor 
                                        invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.</div>
                                </div>
                            </div>
                            <!-- custom accordion -->
                            <div data-aos="fade-left" data-aos-once="true" class="panel panel-default">
                                <div class="panel-heading" role="tab" id="headingFour">
                                    <h4 class="panel-title">
                                        <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Is MERIWEBSHOP right for me?
                                            <img src="../assets/images/+sign.png">
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                                    <div class="panel-body">
                                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor 
                                        invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.</div>
                                </div>
                            </div>
                            <!-- custom-accordion -->
                            <div data-aos="fade-right" data-aos-once="true" class="panel panel-default">
                                <div class="panel-heading" role="tab" id="headingTwo">
                                    <h4 class="panel-title">
                                        <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Can I move to MERIWEBSHOP from another platform?
                                            <img src="../assets/images/+sign.png">
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                    <div class="panel-body">
                                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor 
                                        invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.</div>
                                </div>
                            </div>
                    
                            <div data-aos="fade-left" data-aos-once="true" class="panel panel-default last-accordion">
                                <div class="panel-heading" role="tab" id="headingThree">
                                    <h4 class="panel-title">
                                        <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">                                
                                            Can someone build my site for me?
                                            <img src="../assets/images/+sign.png">
                                        </a>
                                    </h4>
                                </div>
                                <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                                    <div class="panel-body">
                                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor 
                                        invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.</div>
                                </div>
                            </div>
                        </div>
                        <!-- accordion-end -->
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" data-aos-once="true" class="yarn">
                <img src="../assets/images/Yarn.png" alt="">
            </div>
        </section>

        <!-- section opportunities -->
        <section class="opprtunities opportunity-h">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-7 col-lg-6 opportunity-content">
                        <h1 data-aos="fade-right" data-aos-once="true">Generate a Boundless Business Opportunities with Online Store.</h1>
                        <div class="opportunity-button">
                            <button data-aos="fade-up" data-aos-once="true">GET STARTED</button>
                            <p data-aos="fade-left" data-aos-once="true" data-aos-duration="3000">Start Your First Web Site Trial Today<br> No Credit Card Required!</p>
                        </div>
                    </div>
                    <div class="col-md-5 col-lg-6 opportunity-img">
                        <img data-aos="fade-down" data-aos-once="true" data-aos-duration="3000" src="../assets/images/CutLaptop@2x.png" alt="cut-laptop">
                    </div>
                </div>
            </div>
        </section>

        </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Home'
}
</script>
