import axios from '../../plugins/axios';
export const redirect = {
    state: ()=>({
        pending: false,
        data: {
            data: []
        },
        message: null,
        success: null,
        error: null
    }),
    mutations: {
        fetch_redirect_request(state){

            state.pending = true;

        },
        fetch_redirect_success(state, payload){

            state.data = payload.redirect;
            state.pending = false;
            state.success = true;
            state.error = null;
            state.message = payload.message;

        },
        fetch_redirect_failure(state, payload){

            state.data.data = [];
            state.pending = false;
            state.success = false;
            state.error = payload.error;
            state.message = payload.message;

        },
        CLEAR_MODULE(state){

            state.pending = false;
            state.message = null;
            state.success = null;
            state.error = null;

            state.data.data = [];

        }
    },
    actions:{
        async fetchRedirectRequest({commit}){

            commit('fetch_redirect_request');
            try{
                let res = await axios.get('/getRedirectUrl');
                if(res.data.status_code == '1031'){

                    commit('fetch_redirect_success', res.data);

                }
            }catch(ex){

                commit('fetch_redirect_failure', {error: ex, message: ex});
                
            }
            
        }
    },
    getters: {
        redirects(state){

            return state.data;

        },
        isPending(state){

            return state.pending;

        },
        message(state){

            return state.message;
            
        },
        error:(state)=>state.error
    }
}